import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ImageComponent from "./ImageComponent";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
      <ImageComponent refreshInterval={5} />
);
