import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import './App.css';

type ImageComponentProps = {
  refreshInterval: number; // in minutes
};

const parseQueryParam = (param: string | null, defaultValue: number): number =>
    param !== null ? parseInt(param, 10) : defaultValue;
const parseQueryParamStr = (param: string | null, defaultValue: string): string =>
    param !== null ? param : defaultValue;

const parseBooleanQueryParam = (param: string | null, defaultValue: boolean): boolean => {
  if (param === null) {
    return defaultValue;
  }
  return param.toLowerCase() === 'true';
};

const ImageComponent: React.FC<ImageComponentProps> = ({ refreshInterval }) => {
  const apiPrefix = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : '';
  const initialImageUrl = 'fullScreenLogo.png';

  const defaultRefreshIntervalMs = 10 * 1000;
  const params = new URLSearchParams(window.location.search);
  const imageIndex = parseQueryParam(params.get('imageIndex'), -1);
  const promptSize = parseQueryParam(params.get('promptSize'), 14);
  const instance = parseQueryParamStr(params.get('instance'), 'undefined');
  const showCountdown = parseBooleanQueryParam(params.get('showCountdown'), true);
  const showTime = parseBooleanQueryParam(params.get('showTime'), true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [image, setImage] = useState<string>(initialImageUrl);
  const [prompt, setPrompt] = useState<string>('');
  const [nextRefreshTimeMs, setNextRefreshTimeMs] = useState<number>(0);
  const [countdown, setCountdown] = useState<number>(0);
  const [retryCount, setRetryCount] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState(new Date());

  const calculateBackoffDelay = (retryCount: number) => {
    return Math.min(600000, defaultRefreshIntervalMs * Math.pow(1.25, retryCount)); // Cap at 10 minutes
  };

  const preloadImage = useCallback((imageData: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = `data:image/png;base64,${imageData}`;
      img.onload = () => resolve(img.src);
      img.onerror = reject;
    });
  }, []);

  const fetchImages = useCallback(async () => {
    let localRetryCount = retryCount;
    let nextRefreshTimeInMs = calculateBackoffDelay(localRetryCount);
    try {
      setIsLoading(true);
      const { data } = await axios.post(`${apiPrefix}/composite`, { imageIndex, instance });
      const { images, prompt: newPrompt, nextRefreshTime } = data.response;
      if (nextRefreshTime) {
        nextRefreshTimeInMs = new Date(nextRefreshTime).getTime() - new Date().getTime();
      }
      if (images.length > 0) {
        const selectedImageData = images[currentIndex];
        const cachedImage = await preloadImage(selectedImageData);
        setImage(cachedImage);
        setPrompt(newPrompt);
        setCurrentIndex(prev => (prev + 1) % images.length);
        localRetryCount = 0; // Reset local retry count on success
      }
    } catch (error) {
      console.error('Error fetching images:', error);
      localRetryCount += 1;
    } finally {
      nextRefreshTimeInMs = Math.max(defaultRefreshIntervalMs, nextRefreshTimeInMs);
      setNextRefreshTimeMs(nextRefreshTimeInMs);
      setCountdown(nextRefreshTimeInMs);
      setIsLoading(false);
      const nextRefreshDate = new Date(new Date().getTime() + nextRefreshTimeInMs);
      const nextRefreshDateLocal = nextRefreshDate.toLocaleString();  const totalSeconds = Math.floor(nextRefreshTimeInMs / 1000);

      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      const formattedNextRefreshTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      console.log(`Retry ${localRetryCount}. Next refresh time: ${formattedNextRefreshTime}, at ${nextRefreshDateLocal}. is loading: ${isLoading}`);
      setRetryCount(localRetryCount); // Update the retry count state here
    }
  }, [apiPrefix, currentIndex, imageIndex, preloadImage, retryCount]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    fetchImages();
  }, []); // Only on mount

  useEffect(() => {
    if (nextRefreshTimeMs > 0) {
      const intervalId = setInterval(fetchImages, nextRefreshTimeMs);
      return () => clearInterval(intervalId);
    }
  }, [fetchImages, nextRefreshTimeMs]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => {
        const newCountdown = prevCountdown - 1000;
        return newCountdown > 0 ? newCountdown : 0;
      });
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, [nextRefreshTimeMs]);
  // Style objects
  const containerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const imageStyle: React.CSSProperties = {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    transition: 'opacity 2s ease-in-out'
  };

  const promptStyle: React.CSSProperties = {
    fontSize: `${promptSize}px`,
    color: 'white', // Example color, adjust as needed
    textShadow: '2px 2px 4px #333333'
  };

  const progressDivStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '20px',
    right: '20px'
  };

  const backgroundProgressStyle = {
    color: '#ddd', // Example color, adjust as needed
    opacity: 0.25, // Semi-opaque
    position: 'absolute',
    zIndex: 1, // Ensure it's behind the main CircularProgress
  };

  const clockStyle: React.CSSProperties = {
    position: 'absolute',
    color: 'white',
    opacity: 0.75, // Semi-opaque
    padding: '5px 10px',
    borderRadius: '5px',
    fontSize: '16px',
    fontFamily: 'Arial, sans-serif',
    marginRight: '10px', // Space between clock and progress
    bottom: '23px',
    right: '40px'
  };

  // Function to format the time without seconds
  const formatTimeWithoutSeconds = (date: Date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Calculate doughnut progress
  const progressPercent = () => {
    if (nextRefreshTimeMs === 0) return 0;
    return ((nextRefreshTimeMs - countdown) / nextRefreshTimeMs) * 100;
  };

  return (
      <div style={containerStyle}>
        <img src={image} style={imageStyle} alt="Dynamic"/>
        {prompt && promptSize > 0 && (
            <h4 className="imageTitle" style={promptStyle}>{prompt}</h4>
        )}
        {showTime && (
            <div style={clockStyle}>
              {formatTimeWithoutSeconds(currentTime)}
            </div>
        )}
        {showCountdown && (
            <div style={progressDivStyle}>
              <CircularProgress
                  variant="determinate"
                  value={100}
                  size={30}
                  thickness={6}
                  sx={backgroundProgressStyle}
              />
              <CircularProgress
                  variant={isLoading ? 'indeterminate' : 'determinate'}
                  value={progressPercent()}
                  size={30}
                  thickness={6}
              />
            </div>
        )}
      </div>
  );
};

export default ImageComponent;
